<template>
  <div>
    <v-container fluid>
      <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
          <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
          <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-alert v-if="error" type="error">
        {{ error }}
      </v-alert>
      <div v-else style="background: white;">
        <div class="pageHeight" style="width: auto;margin: 10px;padding: 8px;border: 5px solid #787878;">
          <table width="100%">
            <tr>
              <td width="10%">
                <img style="height: 120px; width: 300px" src="https://vierp-test.s3.ap-south-1.amazonaws.com/logo/vu_logo.svg"/>
              </td>
              <td width="90%">
                <p style="margin-left:250px;font-weight: bold; font-size:24px;color: darkorange;">
                  Provisional Admission (AY : {{currentay}})
                </p>
              </td>
            </tr>
          </table>
          <hr style="margin-top: 0px;margin-bottom: 0px;">
          <v-row>
            <v-col cols="12" sm="12">
              <v-card-text style="color:black;">
                      <p style="text-align:justify; line-height:1.2;font-weight:600;">
                        Dear {{name ? name : " ___________"}},
                      </p>
                      <p style="text-align:justify; line-height:1.2;font-weight:600;">
                        This is the Provisional Admission Functionality of Vishwakarma University (VU), Pune. By completing this procedure, you can seek provisional admission to VU ( {{ program ? program :" ____________ " }} programme) for the academic year {{currentay}}.
                      </p>
                      <p style="text-align:justify; line-height:1.2;">
                        Before starting with the provisional admission procedure, kindly ensure that you have detail information about - Approvals, recognitions, accreditations of the University, the eligibility requirement for the programme, Programme curriculum, Fee Structure, Instalment plan, Modes of Payment, Cancellation Rules – Refund Policy, Admission Procedure, List of required documents, Mandatory Undertakings / Understandings. Hostel Details, Campus facilities, etc.                      
                      </p>
                      <p style="text-align:justify; line-height:1.2;">
                        Vishwakarma University never accepts Capitation Fees / Donation from anyone for admission to any programme. VU accepts all payments only digitally (Credit / Debit Card, UPI, NEFT / RTGS). Hence do not pay any amount (cash / cheque) to any Person / Employee for securing admission at VU.                       
                      </p>
                      <p style="text-align:justify; line-height:1.2;font-weight:600;">
                        After clearing all your queries / doubts proceed with Provisional Admission at Vishwakarma University, Pune, for AY : {{currentay}}.
                      </p>
                      <p style="text-align:justify; line-height:1.2;">
                        <ol>  
                            <li>Your provisional admission will be confirmed only after submission of required certificates / documents (original and / or photocopy as applicable) and fees to the University, as per the schedule (within stipulated time) and after granting of eligibility by the concerned authorities of the University.</li>                 
                            <li>For any reason, if the eligibility is not granted by the concerned authorities of the University, then the provisional admission will be cancelled / admission will not be confirmed. Provisional admission is not the assurance / guarantee of confirmed admission to the university / programme.</li>                 
                            <li>At the time of Provisional Admission, you are required to pay Rs. 25,000/- (Twenty Five Thousand) as First Instalment. You are suggested to prefer Nationalized Bank credentials to make payment. In case of payment failure/error, kindly contact on below mentioned contact details. We will help you out to track the form filling and payment transaction details.</li>
                            <li>Filling up the form and payment of fees, together will complete the provisional admission process.</li>
                            <li>
                              Do not take printout of this page. Fill the form properly and make payment of Rs. 25,000/. After the same, you will receive emails from VU which will include
                              <ul>
                                <li>Submitted provisional admission form + Provisional Admission Payment Receipt</li>
                                <li>VU ERP Login Credentials + VU Email Login Credentials</li>
                                <li>Other important information items as attachment/s.</li>
                              </ul>
                            </li>
                        </ol>
                      </p>
                     
                      <p style="text-align:justify; line-height:1.2;">  
                        I have read and understood the above information. I accept the same and proceed with provisional admission at Vishwakarma University (VU), Pune.                        
                      </p>
                      <p style="text-align:justify; line-height:1.2;">  
                        In case of any query  <br>
                        Email : admissions@vupune.ac.in<br> 
                        Contact Numbers : +91 9590300911                         
                      </p>
                      <v-alert type="warning" prominent style="color:black;">
                          <v-checkbox
                                v-model="checkboxterm"
                                color="red"
                                checked="false"
                                :label="'I have carefully read the above information and I accept the same.'"
                            ></v-checkbox>
                        </v-alert>
                        <br>
                      <center v-if="provisionalAdmission!='NA'">
                        <v-btn  v-if="checkboxterm" color="primary"  dark class="mb-2" @click="proceed()">
                          Accept & Proceed
                        </v-btn>
                      </center>
                      <v-alert type="error" v-if="!validation && checkboxterm" prominent >
                        <strong>You have not filled application form yet. Please fill application form first. To fill Application Form  &nbsp;<a href="https://admission.vupune.ac.in/" target="_blank" style="color:darkblue"> Click here</a></strong> 
                        </v-alert>
              </v-card-text>
            </v-col>
          </v-row>
        </div>
        <br>
      </div>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
const CryptoJS = require("crypto-js")
export default {
  data() {
    return {
      error: "",
      snackbar_msg: "",
      color: "",
      snackbar: false,
      checkboxterm : false,
      applicationformfee : 0,
      admissionfees : 0,
      currentay : null,
      currentayid : null,
      organizationid : null,
      organization_name : null,
      applicationNumber:"",
      programName:"",
      name:"",
      program:"",
      validation:"",
      provisionalAdmission:"NA",
    };
  },

  mounted() {
    this.applicationNumber = this.$route.params.applicationNumber;
    this.programName = this.$route.params.programName;
    if (!this.applicationNumber) 
        this.applicationNumber = this.$route.query.applicationNumber;
    if (!this.programName) 
        this.programName = this.$route.query.programName;
    if (this.applicationNumber && this.programName) {
        this.validation=false
    }
    this.onLoad();
  },

  methods: {
    onLoad() {
      var data = {
        applicationNumber : this.applicationNumber,
                programName : this.programName,
            }
      axios
        .post("/AppLearnerAdmission/vuadmissionform",data)
        .then((res) => {
          if (res.data.status == "200") {
            console.log(res.data)
            this.applicationformfee = res.data.applicationformfee;
            this.admissionfees = res.data.admissionfees;
            this.currentay = res.data.currentay;
            this.currentayid = res.data.currentayid;
            this.organizationid = res.data.organizationid;
            this.organization_name = res.data.organization_name;
            this.name = res.data.name;
            this.program = res.data.program;
            this.provisionalAdmission = res.data.provisionalAdmission;
            this.validation = res.data.validation;
          } else if (res.data.status == "401") {
            this.error = res.data.msg;
          } else {
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something Went Wrong...");
        })
        .finally(() => {
          // 'use strict';
        });
    },
    encrypt (src) {
        const passphrase = '987654'
        var data =  CryptoJS.AES.encrypt(src, passphrase).toString();
        return data
    },
    proceed(){
        this.$router.push({
            name: "vu-admission-application",
            query: { 
          _prov : this.encrypt(this.provisionalAdmission+"") 
        }
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
};
</script>
<style>
  .pageHeight {
    height: auto;
  }
</style>

